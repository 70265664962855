<template>
  <jf-layout>
    <div slot="head">
      <el-button type="primary" size="small" @click="add">新增第三方配置</el-button>
      <el-form inline size="small" class="form">
        <el-form-item label="第三方名称：">
          <el-input v-model="params.name" placeholder="请输入第三方名称" @keydown.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="关联企业名称：">
          <el-input v-model="params.companyName" placeholder="请输入关联企业名称" @keydown.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="配置状态：">
          <el-select v-model="params.status" @change="search">
            <el-option label="全部" value=""></el-option>
            <el-option label="正常" value="1"></el-option>
            <el-option label="冻结" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            查询
          </el-button>
          <el-button icon="el-icon-refresh" @click="reset">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table slot="body" border v-loading="loading" :data="list">
      <el-table-column align="center" prop="name" label="第三方配置名称">
        <span slot-scope="{row}" @click="goDetail(row)" class="link">
          {{row.name}}
        </span>
      </el-table-column>

      <el-table-column align="center" prop="subDomains" label="二级域名" />
      <el-table-column align="center" prop="platformLogo" label="平台logo">
        <template slot-scope="{row}">
          <el-image class="img" v-if="row.platformLogo" :src="row.platformLogo" :preview-src-list="[row.platformLogo]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="companyLogo" label="企业默认logo">
        <template slot-scope="{row}">
          <el-image class="img" v-if="row.companyLogo" :src="row.companyLogo" :preview-src-list="[row.companyLogo]">
          </el-image>
        </template>
      </el-table-column>
      <!--  <el-table-column align="center" prop="isNewUser" label="是否允许新建用户">
        <template slot-scope="{row}">
          <span v-if="row.isNewUser===1">是</span>
          <span v-if="row.isNewUser===0">否</span>
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="isModifyLoginCredentials" label="是否允许修改登录凭证">
        <template slot-scope="{row}">
          <span v-if="row.isModifyLoginCredentials===1">是</span>
          <span v-if="row.isModifyLoginCredentials===0">否</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="fallbackAddress" label="回退地址" />
      <el-table-column align="center" prop="numberOfCompanies" label="关联企业数量">
        <span slot-scope="{row}" @click="openCompanyList(row)" :class="row.numberOfCompanies>0?'link':''">
          {{row.numberOfCompanies}}
        </span>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" />
      <el-table-column align="center" prop="status" label="状态">
        <template slot-scope="{row}">
          <el-tag size="mini" v-if="row.status===1">正常</el-tag>
          <el-tag size="mini" v-if="row.status===0" type="danger">冻结</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createDate" label="创建时间" />
      <el-table-column align="left" label="操作" width="180px">
        <template slot-scope="{row}">
          <el-button size="mini" @click="openConfig(row)">SDK配置</el-button>
          <el-button size="mini" v-if="isAuth('platform_config:thirdPartyConfig:update')" @click="edit(row)">编辑</el-button>
          <el-button size="mini" v-if="isAuth('platform_config:thirdPartyConfig:update_status') && row.status===1" type="danger" @click="changeStatus(row,0)">冻结</el-button>
          <el-button size="mini" v-if="isAuth('platform_config:thirdPartyConfig:update_status') && row.status===0" @click="changeStatus(row,1)">恢复</el-button>
          <el-button size="mini" v-if="isAuth('platform_config:thirdPartyConfig:delete') && row.status===0" type="danger" @click="remove(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit.sync="params.limit" :total="total" />
    <Company :show.sync="show" :id="id" @removeCompany="getList" />
    <Config :show.sync="config.show" :thirdPartyConfigId="config.id" />
  </jf-layout>
</template>
<script>
import Company from "./company.vue"
import Config from "./config.vue"
export default {
  components: {
    Company,Config
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        companyName: "",
        name: '',
        status: ''
      },
      total: 0,
      list: [],
      loading: false,
      show: false,
      id: '',
      config: {
        show: false,
        id: ''
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    openConfig(row) {
      this.config.show = true;
      this.config.id = row.id
    },
    openCompanyList(row) {
      this.id = row.id;
      this.show = true;
    },
    add() {
      this.$router.push({
        path: '/user-thirdparty-form',
        query: {
          state: 'add'
        }
      })
    },
    edit(row) {
      console.log(row)
      this.$router.push({
        path: '/user-thirdparty-form',
        query: {
          state: 'edit',
          id: row.id
        }
      })
    },
    goDetail(row) {
      this.$router.push({
        path: '/user-thirdparty-form',
        query: {
          state: 'detail',
          id: row.id
        }
      })
    },
    search() {
      this.params.page = 1;
      this.getList();
    },
    reset() {
      this.params.companyName = "";
      this.params.name = "";
      this.params.status = "";
      this.search();
    },
    getList() {
      this.loading = true;
      this.$get('/platform-admin/thirdPartyConfig/page', this.params).then(res => {
        if (res && res.code === 0) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    changeStatus(row, status) {
      var desc = status === 0 ? '冻结' : '恢复';
      this.$confirm(`确认执行${desc}操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$put('/platform-admin/thirdPartyConfig/updateStatus', {
          id: row.id,
          status
        }).then(res => {
          if (res && res.code === 0) {
            this.$message.success(`${desc}成功`);
            this.getList();
          }
        })
      }).catch(() => { })

    },

    remove(row) {
      this.$confirm('确认执行删除操作?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$del('/platform-admin/thirdPartyConfig', [row.id]);
          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.search();
          }
        })
        .catch(() => { });
    }
  }
}
</script>

<style lang='scss' scoped >
.link {
  cursor: pointer;
  color: #409eff;
  &:active,
  &:hover {
    opacity: 0.7;
  }
}
.img {
  width: 40px;
  height: 40px;
}
.form {
  margin-top: 15px;
}
</style>
